import { PostLayout } from "@/layout";
import { Post } from "@/model";

import { PageProps } from "./type";

export type Context = {
  post: Post;
};

export default function PostPage(
  props: PageProps<Record<string, unknown>, Context>
): JSX.Element {
  const { pageContext, location } = props;
  const { post } = pageContext;
  return <PostLayout post={post} location={location} />;
}
